import React, { useState, useEffect } from 'react';
import {
    Grid,
} from '@mui/material';
import logo from './images/logoBright.png';
import FacebookRoundedIcon from '@mui/icons-material/FacebookRounded';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import GitHubIcon from '@mui/icons-material/GitHub';
import { useNavigate } from 'react-router-dom';



const Home = (props) => {
    const navigate = useNavigate();

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    return (
        <Grid container className='footerContainer' ref={props.footerRef}>
            <Grid container md={12} sm={12} sx={12} display="flex" direction="row" justifyContent="space-between" alignItems="center" className='footerTop'>
                <Grid container md={4} sm={12} sx={12} item display="flex" direction="column" alignItems="flex-start" justifyContent="flex-start">
                    <img src={logo} alt='logo' className='logoStyleFooter' />
                    <p className='m-0 mt-10 fs-14'>FutecX Vision LLC-FZ,
                        Meydan Grandstand, </p>
                    <p className='m-0 mt-5 fs-14'>6th floor,Meydan Road,</p>
                    <p className='m-0 mt-5 fs-14'> Nad Al Sheba, Dubai, U.A.E</p>
                </Grid>
                {!(useWindowDimensions().width <= 100) && <Grid container md={4} sm={6} sx={12} alignSelf={"flex-end"}>
                    <a
                        href="/services"
                        className={'footerLink mr-25 fs-14'}>Services</a>
                    {/* <a
                        href=""
                        className='footerLink ml-25 fs-14'>Blogs</a> */}
                    <a
                        href="/contactUs"
                        onClick={() => {
                            navigate("/contactUs");
                        }}
                        className='footerLink mr-25 fs-14'>Contact Us</a>
                    <a href="/aboutUs" className='footerLink mr-25 fs-14'>About Us</a>
                </Grid>}
                <Grid md={4} sm={12} sx={12} display="flex" direction="column" alignItems={(useWindowDimensions().width <= 800) ? "flex-start" : "flex-end"} alignSelf="flex-end" className={(useWindowDimensions().width <= 800) && "mt-10"}>
                    <Grid item display="flex" direction="row" justifyContent="flex-start" alignItems="center">
                        {/* <FacebookRoundedIcon sx={{ fontSize: "26px" }}  /> */}
                        <a
                            href="https://x.com/FutecX"
                            className='mr-15 cursor-pointer socialHandles'
                        >
                            <XIcon sx={{ fontSize: "1.2rem" }} />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UC0C__JCn91jPgxLojYqdwiQ"
                            className='mr-15 cursor-pointer socialHandles'
                        >
                            <LinkedInIcon sx={{ fontSize: "1.5rem" }} />
                        </a>
                        <a
                            href="https://www.youtube.com/channel/UC0C__JCn91jPgxLojYqdwiQ"
                            className='mr-15 cursor-pointer socialHandles'
                        >
                            <YouTubeIcon sx={{ fontSize: "1.8rem" }} />
                        </a>
                        <a
                            href="https://github.com/futecx/"
                            className='mr-15 cursor-pointer socialHandles'
                        >
                            <GitHubIcon sx={{ fontSize: "1.4rem" }} className='cursor-pointer' />
                        </a>
                    </Grid>
                </Grid>

            </Grid>
            <Grid container display="flex" justifyContent="space-between" alignItems="center" className='mt-10'>
                {/* <Grid container md={12} sm={12} sx={12} display="flex" justifyContent="space-between" alignItems={(useWindowDimensions().width <= 800) ? "flex-start" : "center"}> */}
                <Grid container md={12} sm={12} sx={12}>
                    {/* {!(useWindowDimensions().width <= 800) && <Grid md={4} ></Grid>} */}
                    <Grid md={6} sm={6} sx={12} className='textAlign-left' alignSelf={(useWindowDimensions().width <= 800) ? "center" : "flex-end"}>
                        <a href="/termsAndConditions" className='footerLink fs-14'>Privacy Policy</a>
                        <a href="/termsAndConditions" className='footerLink ml-25 fs-14'>Terms of Service</a>
                        <a href="" className='footerLink ml-25 fs-14'>Cookies Settings</a>
                    </Grid>
                    <Grid md={6} sm={6} sx={12} alignSelf={(useWindowDimensions().width <= 800) ? "center" : "flex-end"} className={(useWindowDimensions().width <= 800) ? 'textAlign-right mt-10' : 'textAlign-right'}>
                        <p className={(useWindowDimensions().width <= 800) ? 'm-0 fs-13 textAlign-right' : 'm-0 fs-13 textAlign-right'}>&copy; 2024 FutecX Vision LLC-FZ. All rights reserved.</p>
                    </Grid>
                </Grid>
            </Grid>
        </Grid >
    )
}

export default Home