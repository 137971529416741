import React, { useEffect, useRef, useState } from 'react';
import {
    Button,
    Grid,
} from '@mui/material';
import banner from './images/banner1.png'



const Banner = (props) => {
    const { scrollToTarget } = props;
    const rightRef = useRef(null);
    const leftRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-right');
                        observer.unobserve(entry.target); // Stop observing the element after animation
                    }
                });
            },
            {
                threshold: 0 // Triggers when 50% of the element is visible
            }
        );

        if (rightRef.current) {
            observer.observe(rightRef.current);
        }

        return () => {
            if (rightRef.current) {
                observer.disconnect(); // Clean up the observer if the component unmounts
            }
        };
    }, []);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }


    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-left');
                        observer.unobserve(entry.target); // Detach observer after the animation
                    }
                });
            },
            {
                threshold: 0 // Adjust based on when you want the animation to start
            }
        );

        if (leftRef.current) {
            observer.observe(leftRef.current);
        }

        return () => {
            observer.disconnect(); // Clean up the observer
        };
    }, []);

    console.log("useWindowDimensions", useWindowDimensions().width <= 800)

    return (
        (useWindowDimensions().width <= 800) ?
            <Grid container className='homeBannerWrapper mb-40'>
                <Grid ref={rightRef} display="flex" alignItems="center" className='bannerImageSmall'>
                    <img src={banner} alt='csepImage' className='homeBannerImageSmall' />
                </Grid>
                < Grid ref={leftRef} className='textAlign-left p-15 animationContainer' display="flex" direction="column" justifyContent="center" >
                    <p className='m-0 bannerHead1'>Nurturing Today's Innovation for a Thriving Future!</p>
                    <p className='m-0 bannerHead2'>Welcome to FutecX, where our vision of tomorrow shapes the technology solutions we provide today.</p>
                    <p className='m-0 bannerHead3'>Our dedication to your success drives us to deliver groundbreaking solutions in Microsoft Azure Cloud Services, Microsoft 365 Solutions, and Cyber Security Advisories tailored for visionary leadership.</p>
                    <p className='m-0 bannerHead4'>Embark on a journey of innovation with us, and witness value creation that propels you to new heights.</p>
                    <Button
                        onClick={() => { scrollToTarget() }}
                        className={`primaryBtnContained mt-30`} sx={{ width: "6.2rem !important" }}>
                        Learn More
                    </Button>
                </Grid >

            </Grid > : <Grid container display="flex" direction="row" justifyContent="space-between" className='homeBannerWrapper mb-40'>
                <Grid item md={6} ref={leftRef} className='textAlign-left pl-10 animationContainer' display="flex" direction="column" justifyContent="center">
                    <p className='m-0 bannerHead1'>Nurturing Today's Innovation for a Thriving Future!</p>
                    <p className='m-0 bannerHead2'>Welcome to FutecX, where our vision of tomorrow shapes the technology solutions we provide today.</p>
                    <p className='m-0 bannerHead3'>Our dedication to your success drives us to deliver groundbreaking solutions in Microsoft Azure Cloud Services, Microsoft 365 Solutions, and Cyber Security Advisories tailored for visionary leadership.</p>
                    <p className='m-0 bannerHead4'>Embark on a journey of innovation with us, and witness value creation that propels you to new heights.</p>
                    <Button
                        onClick={() => { scrollToTarget() }}
                        className={`primaryBtnContained mt-30`} sx={{ width: "6.2rem !important" }}>
                        Learn More
                    </Button>
                </Grid>
                <Grid item md={6} ref={rightRef} className='leftImageContainer' >
                    <img src={banner} alt='csepImage' className='homeBannerImage' />
                </Grid>
            </Grid>
    )
}

export default Banner