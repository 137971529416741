import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import Home from './Home';
import CsepService from './csepPage/CsepService';
import VcupService from './vcupPage/VcupService';
import IdsService from './idsPage/IdsService';
import AboutUs from './AboutUs';
import ContactUs from './ContactUs';
import ServicesPage from './ServicesPage';
import TermsAndConditions from './TermsAndConditions';
import { useEffect } from 'react';
import './App.scss';

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}


function App() {

  return (
    <div className="App">
      <Router>
        <ScrollToTop />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/proactive-managed-services" element={<CsepService />} />
          <Route path="/value-centric-skilling" element={<VcupService />} />
          <Route path="/instant-deployment-services" element={<IdsService />} />
          <Route path="/aboutUs" element={<AboutUs />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/services" element={<ServicesPage />} />
          <Route path="/termsAndConditions" element={<TermsAndConditions />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
