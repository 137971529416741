import React from 'react';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactPhoneIcon from '@mui/icons-material/ContactPhone'; // This is for the main contact button


const ContactIcon = () => {
    const actions = [
        { icon: <PhoneIcon className='contactFixedIcon' />, name: '+971543211447', action: () => handleCall() },
        { icon: <EmailIcon className='contactFixedIcon' />, name: 'info@futecx.com', action: () => handleEmail() },
        { icon: <WhatsAppIcon className='contactFixedIcon' />, name: '+971543211447', action: () => handleWhatsApp() }
    ];

    const handleCall = () => {
        window.location.href = 'tel:+971543211447'; // Replace +1234567890 with your phone number
    };

    const handleEmail = () => {
        window.location.href = 'mailto:info@futecx.com?subject=Inquiry&body=Hi, I would like to discuss'; // Customize the email address and pre-filled content
    };

    const handleWhatsApp = () => {
        window.location.href = 'https://wa.me/+971543211447'; // Replace 1234567890 with your phone number with international code
    };



    return (
        <SpeedDial
            ariaLabel="SpeedDial tooltip example"
            sx={{ position: 'fixed', bottom: 156, right: 26 }}
            className='contactFixedMainIcon'
            icon={<SpeedDialIcon icon={<ContactPhoneIcon />} openIcon={<ContactPhoneIcon />} />}
        >
            {actions.map((action) => (
                <SpeedDialAction
                    key={action.name}
                    icon={action.icon}
                    tooltipTitle={action.name}
                    tooltipOpen
                    className='contactFixedIcon'
                    onClick={action.action}
                />
            ))}
        </SpeedDial>
    );
}

export default ContactIcon
