import React, { useState, useRef } from 'react';
import {
    Grid, Button,
    duration,
} from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BlogCard from './BlogCard';

const carouselDetails = [
    {
        technology: "Technology",
        blogHeading: "How AI is Transforming Business Operations",
        blogDesc: "Discover how AI is revolutionizing business operations and driving growth.",
        blogCreator: "Antony Edwin",
        date: "21 Apr 2024",
        duration: "5"
    },
    {
        technology: "Strategy",
        blogHeading: "The Evolution of Data Engineering: ",
        bogHeading2: "From Batch Processing to Real-Time Analytics",
        blogDesc: "Unlock maximum efficiency and scalability of your Azure Instance with our expertise.",
        blogCreator: "Antony Edwin",
        date: "22 Apr 2024",
        duration: "5"
    },
    {
        technology: "Innovation",
        blogHeading: "How LLM is Shaping the Tech Industry: ",
        bogHeading2: "Transforming Online Learning with Value-Centric Upskilling.",
        blogDesc: "Unleashing the Transformative Power of Large Language Models.",
        blogCreator: "Manoj Saseendran",
        date: "24 Apr 2024",
        duration: "5"
    },
    // {
    //     technology: "Innovation",
    //     blogHeading: "Breaking Down Silos:",
    //     bogHeading2: "How Cross-Functional Collaboration Drives Business Innovations",
    //     blogDesc: "Unleashing the Transformative Power of Large Language Models.",
    //     blogCreator: "Manoj Saseendran",
    //     date: "24 Apr 2024",
    //     duration: "5"
    // }
]


const Carousel = () => {
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    // Custom Previous Arrow
    const PrevArrow = ({ className, style, onClick, carouselNumber, isActive }) => {
        let isArrowNeeded = carouselNumber > 3 ? true : false;
        return (
            <svg
                onClick={onClick}
                className={className}
                style={{ ...style, display: "block", fill: !isArrowNeeded ? 'white' : isActive ? 'black' : 'grey' }}
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
            >
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L11.83 12z" />
            </svg>
        );
    };

    // Custom Next Arrow
    const NextArrow = ({ className, style, onClick, carouselNumber, isActive }) => {
        let isArrowNeeded = carouselNumber > 3 ? true : false;
        console.log("carouselNumber", carouselNumber)
        return (
            <svg
                onClick={onClick}
                className={className}
                style={{ ...style, display: "block", fill: !isArrowNeeded ? 'white' : isActive ? 'black' : 'grey' }}
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
            >
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
            </svg>
        );
    };

    const settings = {
        // dots: true,
        infinite: false,  // Ensure the carousel doesn't loop
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        nextArrow: <NextArrow carouselNumber={carouselDetails.length} isActive={currentSlide ? currentSlide < (sliderRef.current ? sliderRef.current.innerSlider.state.slideCount - 3 : 0) : true} />,
        prevArrow: <PrevArrow carouselNumber={carouselDetails.length} isActive={currentSlide > 0} />,
        afterChange: (current) => {
            console.log("Current Slide Index:", current);
            setCurrentSlide(current);
        },
    };

    return (
        <Slider {...settings} ref={sliderRef} className='carouselContainer'>
            {carouselDetails.map((carouselData) => (
                <div>
                    <BlogCard carouselData={carouselData} />
                </div>
            ))}
            {/* <div>
                <BlogCard />
            </div>
            <div>
                <BlogCard />
            </div>
            <div>
                <BlogCard />
            </div> */}
        </Slider>
    )
}

export default Carousel