import React from 'react';
import {
    Grid,
} from '@mui/material';
import placeHolder from '../images/placeholder.jpg';
import idsArchitect from '../images/idsArchitect.jpeg';



const IdsContent = () => {
    return (
        <Grid container display="flex" direction="column" justifyContent="space-between" className='serviceWrapper'>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Our Offerings Include </p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Custom IT Solutions: </span> Tailored designs and implementations to meet your specific business requirements. </p>
            <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Expert Guidance: </span> Direct access to our team of seasoned IT and cybersecurity professionals. </p>
            <p className='m-0 fs-18 textAlign-left mb-40'><span className='fw-700'>Flexible Engagement:  </span> Choose from a range of services, from single projects to ongoing support, customized to your needs. </p>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Technologies We Specialize In </p>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                <Grid item md={5}>
                    <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Microsoft Azure Cloud: </span>  Comprehensive management and optimization of Azure environments, including Azure Active Directory, Azure DevOps, and Azure AI. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Microsoft 365 Solutions: </span> Enhancements and custom implementations to improve productivity and collaboration. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Cybersecurity Solutions:  </span> Advanced setups featuring endpoint protection, network security, and threat intelligence, leveraging frameworks like ISO/IEC 27001 and NIST. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Data and AI Technologies: </span> Implementation of big data solutions, machine learning, and AI analytics to bolster decision-making and efficiency. </p>
                </Grid>
                <Grid item md={7} className='imageContainer'>
                    <img src={idsArchitect} alt='csepOffers' className='csepOfferImage' />
                </Grid>
            </Grid>
            <p className='m-0 fs-18 textAlign-left mb-40'><span className='fw-700'>Data Engineering and Redesign: </span> Expert advice on developing data pipelines and transforming existing databases into efficient data warehouses. </p>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Key Features</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Broad Technology Expertise: </span>  From cloud infrastructure to applications and cybersecurity, our expertise covers all essential IT areas. </p>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                <Grid item md={7} className='imageContainer pr-10'>
                    <img src={idsArchitect} alt='csepOffers' className='csepOfferImage' />
                </Grid>
                <Grid item md={5}>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Cutting-Edge Technology: </span>  We utilize the latest technology and best practices for optimal performance and security. </p>
                    <p className='m-0 fs-18 textAlign-left mb-40'><span className='fw-700'>Scalable Solutions: </span> Our services are designed to grow with your business and adapt to evolving technological needs. </p>
                    <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Benefits</p>
                    <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Enhanced Efficiency: </span>  Reduce costs and streamline operations with our state-of-the-art technology solutions. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Stronger Security: </span>   Enhance your defense against cyber threats with robust security strategies and tools. </p>
                    <p className='m-0 fs-18 textAlign-left mb-40'><span className='fw-700'>Innovation Advantage: </span> Stay ahead with access to the newest technologies and innovative IT strategies. </p>
                </Grid>

            </Grid>

            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Pricing and Timeline</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Transparent Pricing: </span>   Our project-based pricing reflects the scope and complexity, ensuring you receive excellent value. </p>
            <p className='m-0 fs-18 textAlign-left mb-20'><span className='fw-700'>Custom Timelines: </span>  We prioritize prompt delivery while maintaining the highest quality standards, customizing timelines to fit the project scope. </p>
            <p className='m-0 fs-18 textAlign-left mb-10'>Choose FutecX Instant Deployment Services for expertly tailored IT solutions that align with your business objectives and drive technological advancement. </p>
        </Grid>
    )
}

export default IdsContent