import React, { useState, useEffect, useRef } from 'react';
import {
    Grid,
} from '@mui/material';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Csep from './service/Csep';
import Vcrp from './service/Vcrp';
import Odps from './service/Odps';



const Services = (props) => {
    const { myTarget } = props;
    const [currentSlide, setCurrentSlide] = useState(0);
    const sliderRef = useRef(null);

    // Custom Previous Arrow
    const PrevArrow = ({ className, style, onClick, carouselNumber, isActive }) => {
        let isArrowNeeded = carouselNumber > 1 ? true : false;
        return (
            <svg
                onClick={onClick}
                className={className}
                style={{ ...style, display: "block", fill: !isArrowNeeded ? 'white' : isActive ? 'black' : 'grey' }}
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
            >
                <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L11.83 12z" />
            </svg>
        );
    };

    // Custom Next Arrow
    const NextArrow = ({ className, style, onClick, carouselNumber, isActive }) => {
        let isArrowNeeded = carouselNumber > 3 ? true : false;
        console.log("carouselNumber", carouselNumber)
        return (
            <svg
                onClick={onClick}
                className={className}
                style={{ ...style, display: "block", fill: !isArrowNeeded ? 'white' : isActive ? 'black' : 'grey' }}
                width="24px"
                height="24px"
                viewBox="0 0 24 24"
            >
                <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6z" />
            </svg>
        );
    };

    const settings = {
        dots: true,
        infinite: true,  // Ensure the carousel doesn't loop
        speed: 500,
        fade: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 5000,
        cssEase: "ease-in-out"
    };


    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }


    return (
        <Grid container className='serviceWrapper'>
            <Odps myTarget={myTarget} />
            <Vcrp />
            <Csep />
        </Grid>
    )
}

export default Services