import React, { useEffect, useRef, useState } from 'react';
import {
    Grid, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import csepImage from '../images/VCEP1.png';



const Vcrp = () => {
    const navigate = useNavigate();
    const rightRef = useRef(null);
    const leftRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-right');
                        observer.unobserve(entry.target); // Stop observing the element after animation
                    }
                });
            },
            {
                threshold: 0 // Triggers when 50% of the element is visible
            }
        );

        if (rightRef.current) {
            observer.observe(rightRef.current);
        }

        return () => {
            if (rightRef.current) {
                observer.disconnect(); // Clean up the observer if the component unmounts
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-left');
                        observer.unobserve(entry.target); // Detach observer after the animation
                    }
                });
            },
            {
                threshold: 0 // Adjust based on when you want the animation to start
            }
        );

        if (leftRef.current) {
            observer.observe(leftRef.current);
        }

        return () => {
            observer.disconnect(); // Clean up the observer
        };
    }, []);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    return (
        <Grid container display="flex" direction={(useWindowDimensions().width <= 800) ? "column-reverse" : "row"} justifyContent="space-between" className='vcrpWrapper'>
            <Grid item md={6} ref={leftRef} className='rightTextContainer'>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 serviceHeadText pl-5' : 'm-0 serviceHeadText br-2 pr-20'}>Value Centric Skilling (VCS)</p>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 mt-20 serviceOverview2 pl-5' : 'm-0 mt-30 serviceOverview2 pr-20'}>Transform Your Skills for Tomorrow, Today</p>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 mt-20 serviceOverviewText pl-5' : 'm-0 mt-20 serviceOverviewText pr-20'}>In an ever-evolving technological landscape, staying ahead isn't just about keeping pace; it's about leading. At FutecX, we've reimagined professional growth with our Value Centric Skilling. Unlike traditional training models focused on rigid curricula and exam preparations, our program is designed to mold the contours of real-world applications and futuristic technologies.</p>
                <Button
                    onClick={() => {
                        navigate('/value-centric-skilling')
                    }}
                    className={(useWindowDimensions().width <= 800) ? `primaryBtnContained mt-30 ml-5` : `primaryBtnContained mt-30 mr-20`} sx={{ width: "6.2rem !important" }}>
                    Learn More
                </Button>
            </Grid>
            <Grid item md={6} ref={rightRef} className='rightImageContainer'>
                <img src={csepImage} alt='csepImage' className='leftImage' />
            </Grid>
        </Grid>
    )
}

export default Vcrp