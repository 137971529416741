import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, IconButton, Link,
    Button, MenuItem, Menu, Drawer
} from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import aboutUsBanner from './images/aboutUs.jpeg';



const AboutUs = (props) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isActiveSideBar, setIsActiveSideBar] = useState(false);
    const footerRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(!entry.isIntersecting);
            },
            {
                root: null, // observing intersections with the viewport
                threshold: 0.1 // trigger callback when 10% of the footer is visible
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    return (
        <>
            <Grid container className='homeBg'>
                {isVisible && <Header setIsActiveSideBar={setIsActiveSideBar} />}
                <Grid container>
                    <Grid container className='aboutBannerContainer'>
                        <div className='aboutUsImageContainer'>
                            <img
                                src={aboutUsBanner} alt="Logo" className='aboutUsImage'
                            >
                            </img>
                            <p className='m-0 fs-20 fw-700 bannerText'>At FutecX Vision LLC, we empower businesses to
                                excel in the rapidly evolving technological landscape.</p>
                        </div>
                    </Grid>
                    <Grid container className='aboutUsContent'>
                        <p className='m-0 fs-18 mb-40'><span className='fw-700'>FutecX</span>, short for <span className='fw-700'>Futuristic Technology Experts</span>, represents our
                            deep commitment to ground-breaking undiscovered
                            opportunities. By tailoring our strategies to your unique vision,
                            we ensure measurable outcomes. Driven by a passion for
                            innovation, we harness the transformative power of AI to keep
                            our clients at the forefront of their industries.</p>

                        <p className='m-0 fs-24 fw-700 mt-10 displayBlock width100'>Our Values</p>
                        <p className='m-0 fs-18 mt-10'>At FutecX, we are driven by <span className='fw-700'>C<span className='blueText'>ustomer-Obsession</span></span>,
                            <span className='fw-700'> U<span className='blueText'>nwavering-Commitment</span></span>, and <span className='fw-700'>C<span className='blueText'>ontinuous-Learning</span></span>, all founded
                            on our principle of <span className='fw-700'>E<span className='blueText'>fficient Minimalism</span></span>.</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>We harness <span className='fw-700'>Creativity</span> and <span className='fw-700'>Continuous Advancement</span> to
                            deliver not only what our customers need but also what
                            they envision, ensuring that our solutions are as
                            streamlined and effective as possible.</p>
                            <p className='m-0 fs-24 fw-700 mt-10'>Our mission</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>At FutecX, our mission is to empower
                            every businesses to excel in the dynamic digital landscape.
                            We’re committed to offer cutting-edge services that not only
                            meet today’s needs but also anticipate <span className='fw-700 blueText'>Future challenges</span>.</p>
                    </Grid>
                </Grid>
                <Footer footerRef={footerRef} />
                <Drawer open={isActiveSideBar} onClose={() => { setIsActiveSideBar(false); }}>
                    <SideBar setIsActiveSideBar={setIsActiveSideBar} />
                </Drawer>
            </Grid>
        </>
    )
}

export default AboutUs