import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, Drawer,
} from '@mui/material';
import Header from '../Header';
import Footer from '../Footer';
import SideBar from '../SideBar';
import CsepBanner from './CsepBanner';
import CsepContent from './CsepContent';
import { useLocation } from 'react-router-dom';



const CsepService = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isActiveSideBar, setIsActiveSideBar] = useState(false);
    const footerRef = useRef(null);
    const { pathname } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(!entry.isIntersecting);
            },
            {
                root: null, // observing intersections with the viewport
                threshold: 0.1 // trigger callback when 10% of the footer is visible
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    console.log("isVisible", isVisible)

    return (
        <>=
            <Grid container className='homeBg'>
                {isVisible && <Header setIsActiveSideBar={setIsActiveSideBar} />}
                <CsepBanner />
                <CsepContent />
                <Footer footerRef={footerRef} />
                <Drawer open={isActiveSideBar} onClose={() => { setIsActiveSideBar(false); }}>
                    <SideBar setIsActiveSideBar={setIsActiveSideBar} />
                </Drawer>
            </Grid>
        </>
    )
}

export default CsepService