import React from 'react';
import {
    Grid, AppBar, IconButton, Link,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import logoBright from './images/logoBright.png';
import { useNavigate } from 'react-router-dom';



const Header = (props) => {
    const { setIsActiveSideBar } = props;
    const navigate = useNavigate();

    return (
        <AppBar position="fixed" className='headerWrapper'>
            <Grid container md={12} display="flex" justifyContent="space-between" className='head'>
                <Grid flex={1} container>
                    <IconButton
                        // edge="start"
                        className='menuIcon'
                        // color='black'
                        onClick={() => { setIsActiveSideBar(true); }}
                    >
                        <MenuIcon sx={{ fontSize: "2rem", color: 'black' }} />
                    </IconButton>
                    <img
                        onClick={() => {
                            navigate("/");
                        }}
                        src={logoBright} alt="Logo" className='logoStyle'
                    />
                </Grid>
                <Grid sm={6} md={5} item alignSelf="flex-end" display="flex" justifyContent="flex-end" alignItems="center" className='servicesMenuWrap'>
                    <Grid md={9} alignSelf="flex-end" container item display="flex" justifyContent="space-between" alignItems="center">
                        <Link to='/approve/callTree' className='headermenu serviceMenu'>
                            <Grid container display="flex" justifyContent="flex-start" alignItems="center">
                                <p className='m-0'>Services</p>
                                <ArrowDropDownIcon sx={{ fontSize: 35, paddingTop: "2px !important" }} />
                            </Grid>
                            <Grid container className='menuContent'>
                                <Grid className='menuWrapper'>
                                    <div className='menuItem'
                                        onClick={() => {
                                            navigate("/instant-deployment-services");
                                        }}
                                    >Instant Deployment Services (IDS)</div>
                                    <div onClick={() => {
                                        navigate("/value-centric-skilling");
                                    }} className='menuItem'>Value Centric Skilling (VCS)</div>
                                    <div className='menuItem'
                                        onClick={() => {
                                            navigate("/proactive-managed-services");
                                        }}
                                    >Proactive Managed Services (PMS)</div >
                                </Grid>
                            </Grid>
                        </Link>
                        {/* <Link to='/approve/callTree' className='headermenu'>
                            <p className='m-0'>Blogs</p>
                        </Link> */}
                        <Link
                            onClick={() => {
                                navigate("/contactUs");
                            }}
                            to='/contactUs' className='headermenu'>
                            <p className='m-0'>Contact Us</p>
                        </Link>
                        <Link
                            onClick={() => {
                                navigate("/aboutUs");
                            }}
                            to='/aboutUs' className='headermenu'>
                            <p className='m-0'>About Us</p>
                        </Link>

                        {/* <Link
                            onClick={() => {
                                navigate("/blogs");
                            }}
                            to='/blogs' className='headermenu'>
                            <p className='m-0'>Blogs</p>
                        </Link> */}
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    )
}

export default Header