// import React from 'react';
import React, { useEffect, useRef, useState } from 'react';
import {
    Grid, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import idsImage from '../images/IDS3.png';



const Odps = (props) => {
    const { myTarget } = props;
    const navigate = useNavigate();
    const rightRef = useRef(null);
    const leftRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-right');
                        observer.unobserve(entry.target); // Stop observing the element after animation
                    }
                });
            },
            {
                threshold: 0// Triggers when 50% of the element is visible
            }
        );

        if (rightRef.current) {
            observer.observe(rightRef.current);
        }

        return () => {
            if (rightRef.current) {
                observer.disconnect(); // Clean up the observer if the component unmounts
            }
        };
    }, []);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-left');
                        observer.unobserve(entry.target); // Detach observer after the animation
                    }
                });
            },
            {
                threshold: 0// Adjust based on when you want the animation to start
            }
        );

        if (leftRef.current) {
            observer.observe(leftRef.current);
        }

        return () => {
            observer.disconnect(); // Clean up the observer
        };
    }, []);

    return (
        <Grid ref={myTarget} container display="flex" direction="row" justifyContent="space-between" className='odpsWrapper'>
            <Grid item md={6} ref={leftRef} className={(useWindowDimensions().width <= 800) ? 'leftImageContainer' : 'leftImageContainer pr-10'}>
                <img src={idsImage} alt='idsImage' className='leftImageIds' />
            </Grid>
            <Grid item md={6} ref={rightRef} className='rightContainer'>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 serviceHeadText mt-10 pl-5' : 'm-0 serviceHeadText bl-2 pl-20'}>Instant Deployment Services (IDS)</p>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 mt-20 serviceOverview2 pl-5' : 'm-0 mt-30 serviceOverview2 pl-20'}>Empower Your Business with Expert IT Solutions Tailored to Your Needs</p>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 mt-20 serviceOverviewText  pl-5' : 'm-0 mt-30 serviceOverviewText pl-20'}> FUTECX's Instant Deployment Services (IDS) are designed to support businesses in navigating the complex landscape of IT infrastructure, applications, data, and AI technologies. Specializing in Microsoft Azure Cloud, Microsoft 365 Solutions, and Cyber Security Solutions, our service ensures that your technology aligns with your strategic goals, driving efficiency and innovation.</p>
                <Button
                    onClick={() => {
                        navigate('/instant-deployment-services')
                    }}
                    className={(useWindowDimensions().width <= 800) ? `primaryBtnContained mt-20 ml-5` : `primaryBtnContained mt-30 ml-20`} sx={{ width: "6.2rem !important" }}>
                    Learn More
                </Button>
            </Grid>
        </Grid >
    )
}

export default Odps