import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, IconButton, Link,
    Button, MenuItem, Menu, Drawer
} from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import aboutUsBanner from './images/aboutUs.jpeg';



const TermsAndConditions = (props) => {
    const [isVisible, setIsVisible] = useState(true);
    const [isActiveSideBar, setIsActiveSideBar] = useState(false);
    const footerRef = useRef(null);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(!entry.isIntersecting);
            },
            {
                root: null, // observing intersections with the viewport
                threshold: 0.1 // trigger callback when 10% of the footer is visible
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    return (
        <>
            <Grid container className='homeBg'>
                {isVisible && <Header setIsActiveSideBar={setIsActiveSideBar} />}
                <Grid container>
                    <Grid container className={(useWindowDimensions().width <= 800) ? 'termsAndConditionsContentSmall' : 'termsAndConditionsContent'} >
                        <p className='m-0 fs-24 fw-700 mt-10 mb-20 displayBlock width100'>Terms And Condtions / Privacy Policy</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Introduction</p>
                        <p className='m-0 fs-18 mt-10'><span className='fw-700'>Welcome to FutecX !</span> These terms and conditions outline the rules and regulations for the use of FutecX's Website, located at futecx.com.</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>By accessing this website, we assume you accept these terms and conditions. Do not continue to use futecx.com if you do not agree to take all the terms and conditions stated on this page.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Privacy Policy</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>Our Privacy Policy, which is incorporated into these Terms and Conditions, explains how we collect, use, and disclose your personal information. Please review our Privacy Policy carefully.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Cookies and Tracking Technologies</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>The website uses cookies and other tracking technologies to help personalize your online experience and for analytics purposes. By accessing FutecX, you agree to our use of cookies as described in our Cookie Policy.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>License</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>Unless otherwise stated, FutecX and/or its licensors own the intellectual property rights for all material on FutecX. All intellectual property rights are reserved. You may access this from FutecX for your personal use subject to restrictions set in these terms and conditions.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>You must not</p>
                        <p className='m-0 fs-18 mt-10 mb-5 displayBlock width100'>- Republish material from FutecX</p>
                        <p className='m-0 fs-18 mt-10 mb-5 displayBlock width100'>- Sell, rent, or sub-license material from FutecX </p>
                        <p className='m-0 fs-18 mt-10 mb-5 displayBlock width100'>- Reproduce, duplicate, or copy material from FutecX</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>- Redistribute content from FutecX</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>User Content</p>
                        <p className='m-0 fs-18 mt-10 mb-10'>Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. FutecX does not filter, edit, publish, or review Comments before their presence on the website. Comments do not reflect the views and opinions of FutecX, its agents, and/or affiliates. Comments reflect the views and opinions of the person who posts their views and opinions. To the extent permitted by applicable laws, FutecX shall not be liable for the Comments or any liability, damages, or expenses caused and/or suffered because of any use of and/or posting of and/or appearance of the Comments on this website.</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>FutecX reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive, or cause a breach of these Terms and Conditions.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Data Processing and Retention</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>We process your data for the purposes described in our Privacy Policy, based on legitimate interests, the performance of a contract, or your consent, as applicable. We retain your personal data for as long as necessary to fulfil these purposes, subject to applicable legal requirements.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Your Rights</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>Under the General Data Protection Regulation (GDPR), you have certain rights regarding your personal data, including the right to access, rectify, erase, restrict processing, object to processing, and data portability. You also have the right to withdraw your consent at any time. Please see our Privacy Policy for more information about exercising these rights.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Children's Privacy</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>Our website is not intended for children under the age of 16. We do not knowingly collect personal data from children under 16. If you are a parent or guardian and believe that your child has provided personal data to us, please contact us so that we can take appropriate action.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Data Transfers</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>If we transfer your personal data outside the European Economic Area (EEA), we will ensure that appropriate safeguards are in place to protect your data, as required by the GDPR.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Third-Party Services</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>We may use third-party services for analytics, advertising, and other purposes. These third parties may collect, use, and share your information by their privacy policies. We encourage you to review the privacy policies of these third parties.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Data Protection Officer</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>If you have any questions or concerns about our data practices, you can contact our Data Protection Officer at [email protected]</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Updates</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>We may update these Terms and Conditions and our Privacy Policy from time to time. We will notify you of any material changes by posting the updated versions on our website.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Contact Information</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>If you have any questions or concerns about these Terms and Conditions or our Privacy Policy, please contact us at support@futecx.com</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Complaints</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>If you believe that your data protection rights have been violated, you have the right to complain with the appropriate supervisory authority.</p>
                        <p className='m-0 fs-22 fw-700 mt-10 displayBlock width100'>Disclaimer</p>
                        <p className='m-0 fs-18 mt-10 mb-10'>The information provided by FutecX on futecx.com is for general informational purposes only. All information on the site is provided in good faith; however, we make no representation or warranty of any kind, express or implied, regarding the accuracy, adequacy, validity, reliability, availability, or completeness of any information on the site.</p>
                        <p className='m-0 fs-18 mt-10 mb-40'>By using this website, you acknowledge that you have read, understood, and agreed to be bound by these Terms and Conditions, including our Privacy Policy and Cookie Policy. If you do not agree to these terms, please refrain from using our website.</p>
                    </Grid>
                </Grid>
                <Footer footerRef={footerRef} />
                <Drawer open={isActiveSideBar} onClose={() => { setIsActiveSideBar(false); }}>
                    <SideBar setIsActiveSideBar={setIsActiveSideBar} />
                </Drawer>
            </Grid>
        </>
    )
}

export default TermsAndConditions