import React from 'react';
import {
    Grid, Button,
} from '@mui/material';
import Carousel from './Carousel';



const BlogContainer = () => {

    return (
        <Grid container className='mb-40'>
            <Grid container display="flex" direction="row" justifyContent="space-between" alignItems="flex-end" className='blogsWrapper'>
                <Grid container flex={1} item display="flex" direction="column" justifyContent="flex-start" alignItems="flex-start">
                    <p className='m-0 fs-18 fw-600'>Blogs</p>
                    <p className='m-0 fs-38 mt-20 fw-700'>Explore the Latest Insights</p>
                    <p className='m-0 fs-18 mt-20'>Stay informed with our industry insights and company news.</p>
                </Grid>
                <Grid item flex={1} display="flex" justifyContent="flex-end">
                    <Button className={`primaryBtn mt-30 ml-20`} sx={{ width: "105px !important" }}>
                        View All
                    </Button>
                </Grid>
            </Grid>
            <Grid container>
                <Carousel />
            </Grid>
        </Grid>
    )
}

export default BlogContainer