import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import Slide from '@mui/material/Slide';

const ToastMessage = (props) => {
    const { showToast, closeSnackBar, toastMessge, type, duration = 2000, vertical = "bottom", horizontal = "center" } = props;

    const Alert = React.forwardRef(function Alert(props, ref) {
        return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
    });

    function SlideTransition(props) {
        return <Slide {...props} direction="up" />;
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical, horizontal }}
            open={showToast} autoHideDuration={duration} onClose={() => { closeSnackBar() }}
            TransitionComponent={SlideTransition}
        >
            <Alert onClose={() => { closeSnackBar() }} severity={type} sx={{ width: '100%' }}>
                {toastMessge}
            </Alert>
        </Snackbar>
    )
}

export default ToastMessage;