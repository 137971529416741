import React, { useEffect, useRef, useState } from 'react';
import {
    Grid, Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
// import csepImage from '../images/CSEP4.png';
import csepImage from '../images/CSEP_Banner.png'



const Csep = () => {
    const navigate = useNavigate();
    const rightRef = useRef(null);
    const leftRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-right');
                        observer.unobserve(entry.target); // Stop observing the element after animation
                    }
                });
            },
            {
                threshold: 0 // Triggers when 50% of the element is visible
            }
        );

        if (rightRef.current) {
            observer.observe(rightRef.current);
        }

        return () => {
            if (rightRef.current) {
                observer.disconnect(); // Clean up the observer if the component unmounts
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-left');
                        observer.unobserve(entry.target); // Detach observer after the animation
                    }
                });
            },
            {
                threshold: 0 // Adjust based on when you want the animation to start
            }
        );

        if (leftRef.current) {
            observer.observe(leftRef.current);
        }

        return () => {
            observer.disconnect(); // Clean up the observer
        };
    }, []);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    return (
        <Grid container display="flex" direction="row" justifyContent="space-between" className='pmsWrap'>
            <Grid item md={6} ref={leftRef} className='leftImageContainer'>
                <img src={csepImage} alt='csepImage' className='leftImage' />
            </Grid>
            <Grid item md={6} ref={rightRef} className='rightContainer'>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 serviceHeadText pl-5' : 'm-0 serviceHeadText bl-2 pl-20'}>Proactive Managed Services (PMS)</p>
                <p className={(useWindowDimensions().width <= 800) ? 'm-0 mt-20 serviceOverviewText pl-5' : 'm-0 mt-30 serviceOverviewText pl-20'}>At FutecX, our Proactive Managed Services (PMS) is meticulously designed to optimize your technological infrastructure by addressing key operational challenges through a systematic, data-driven approach. Our objective is to enhance your systems' security, performance, reliability, and cost-efficiency, ensuring you derive maximum value from your investments.</p>
                <Button
                    onClick={() => {
                        navigate('/proactive-managed-services')
                    }}
                    className={(useWindowDimensions().width <= 800) ? `primaryBtnContained mt-20 ml-5` : `primaryBtnContained mt-30 ml-20`} sx={{ width: "6.2rem !important" }}>
                    Learn More
                </Button>
            </Grid>
        </Grid>
    )
}

export default Csep