import React, { useEffect, useRef } from 'react';
import {
    Grid
} from '@mui/material';
import idsImage from '../images/IDS3.png';



const IdsBanner = () => {
    const rightRef = useRef(null);
    const leftRef = useRef(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-right');
                        observer.unobserve(entry.target); // Stop observing the element after animation
                    }
                });
            },
            {
                threshold: 0 // Triggers when 50% of the element is visible
            }
        );

        if (rightRef.current) {
            observer.observe(rightRef.current);
        }

        return () => {
            if (rightRef.current) {
                observer.disconnect(); // Clean up the observer if the component unmounts
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-left');
                        observer.unobserve(entry.target); // Detach observer after the animation
                    }
                });
            },
            {
                threshold: 0 // Adjust based on when you want the animation to start
            }
        );

        if (leftRef.current) {
            observer.observe(leftRef.current);
        }

        return () => {
            observer.disconnect(); // Clean up the observer
        };
    }, []);

    return (
        <Grid container display="flex" direction="row" justifyContent="space-between" className='csepWrapper mb-40'>
            <Grid item md={6} ref={leftRef} className='leftImageContainer pl-10'>
                <img src={idsImage} alt='idsImage' className='idsBannerImage' />
            </Grid>
            <Grid item md={6} ref={rightRef} className='rightContainerBanner' display="flex" direction="column" justifyContent="center">
                <p className='m-0 serviceHeadText pl-20'>Instant Deployment Services (IDS)</p>
                <p className='m-0 mt-30 serviceOverview2 pl-20'>Empower Your Business with Customized Expert IT Solutions </p>
                <p className='m-0 mt-30 serviceOverviewText pl-20'>FutecX Instant Deployment Services offer specialized assistance to businesses grappling with the complexities of IT infrastructure, applications, data, and AI technologies. Our focus on Microsoft Azure Cloud, Microsoft 365 Solutions, Data Engineering, Data Modeling, and Cybersecurity Solutions ensures your technology is strategically aligned to enhance efficiency and drive innovation. </p>
            </Grid>
        </Grid>
    )
}

export default IdsBanner