import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, IconButton, Link,
    Button, MenuItem, Menu, Drawer, TextField
} from '@mui/material';
import Header from './Header';
import Footer from './Footer';
import SideBar from './SideBar';
import ToastMessage from './ToastMessage';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import ContactIcon from './ContactIcon';



const ContactUs = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isActiveSideBar, setIsActiveSideBar] = useState(false);
    const footerRef = useRef(null);
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [mobile, setMobile] = useState("");
    const [description, setDescription] = useState("");
    const [companyName, setCompanyName] = useState("");
    const [isShowToast, setIsShowToast] = useState(false);


    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(!entry.isIntersecting);
            },
            {
                root: null, // observing intersections with the viewport
                threshold: 0.1 // trigger callback when 10% of the footer is visible
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    const ContactAction = () => {
        let headers = new Headers()
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');
        // return (dispatch, getState) => {
        fetch(`https://api.sheety.co/e3e42c741e345bd2382c31d58dbf2e50/futecx/contactUs`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify({
                "contactus": {
                    name,
                    email,
                    description,
                    mobile,
                    companyName
                }
            })
        })
            .then(response => {
                if (response.ok) { // Checks if status code is within the range 200-299
                    return response.json();
                }
            })
            .then((json) => {
                console.log("contactus", json)
                setIsShowToast(true);
                setEmail("");
                setName("");
                setMobile("");
                setDescription("");
                setCompanyName("");

            })
            .catch((error) => {
                console.log(error);
                // dispatch(isError(error))
            })
        // }
    }

    const closeSnackBar = () => {
        setIsShowToast(false);
    }


    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }


    const handleWhatsApp = () => {
        window.location.href = 'https://wa.me/+971543211447'; // Replace 1234567890 with your phone number with international code
    };


    return (
        <>
            <Grid container className='homeBg'>
                {((useWindowDimensions().width > 1440) || isVisible) && <Header setIsActiveSideBar={setIsActiveSideBar} />}
                <Grid md={12} sm={12} sx={12} className='contactUsWrapper'>
                    <p className={(useWindowDimensions().width <= 800) ? 'm-0 fs-28 fw-700 displayBlock textAlign-center' : 'm-0 fs-28 fw-700 displayBlock textAlign-center mt-30'}>Hello. Let's talk.</p>
                    <Grid display="flex" container md={12} sm={12} sx={12} justifyContent="center">
                        <Grid display="flex" direction="column" justifyContent="center" md={10} sm={11} sx={12} alignSelf="center" className='contactUsForm'>
                            <Grid container display="flex" justifyContent="flex-start" direction="row" className='mb-30'>
                                <Grid item md={3} alignSelf="flex-start">
                                    <p className='filedName'>Name <span className='redText'>*</span></p>
                                </Grid>
                                <Grid item md={9}>
                                    <TextField
                                        id="Name" size="small"
                                        label="Name" variant="outlined" type="text"
                                        value={name}
                                        onChange={(e) => { setName(e.target.value) }}
                                        fullWidth
                                        required
                                        className="userDetailInput"
                                        sx={{
                                            height: "30px !important",
                                            fontSize: "13px !important",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display="flex" justifyContent="flex-start" direction="row" className='mb-30'>
                                <Grid item md={3} alignSelf="flex-start">
                                    <p className='filedName'>Email <span className='redText'>*</span></p>
                                </Grid>
                                <Grid item md={9}>
                                    <TextField
                                        id="Email" size="small"
                                        label="Email" variant="outlined" type="text"
                                        required
                                        value={email}
                                        onChange={(e) => { setEmail(e.target.value) }}
                                        fullWidth
                                        className="userDetailInput"
                                        sx={{
                                            height: "30px !important",
                                            fontSize: "13px !important",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display="flex" justifyContent="flex-start" direction="row" className='mb-30'>
                                <Grid item md={3} alignSelf="flex-start">
                                    <p className='filedName'>Mobile</p>
                                </Grid>
                                <Grid item md={9}>
                                    <TextField
                                        id="Mobile" size="small"
                                        label="Mobile" variant="outlined" type="text"
                                        value={mobile}
                                        onChange={(e) => { setMobile(e.target.value) }}
                                        fullWidth
                                        className="userDetailInput"
                                        sx={{
                                            height: "30px !important",
                                            fontSize: "13px !important",
                                        }}
                                    // disabled={(actionType === "view") ? true : false}
                                    />
                                </Grid>
                            </Grid>

                            <Grid container display="flex" justifyContent="flex-start" direction="row" className='mb-30'>
                                <Grid item md={3} alignSelf="flex-start">
                                    <p className='filedName'>Company Name</p>
                                </Grid>
                                <Grid item md={9}>
                                    <TextField
                                        id="CompanyName" size="small"
                                        label="CompanyName" variant="outlined" type="text"
                                        value={companyName}
                                        onChange={(e) => { setCompanyName(e.target.value) }}
                                        fullWidth
                                        // required
                                        className="userDetailInput"
                                        sx={{
                                            height: "30px !important",
                                            fontSize: "13px !important",
                                        }}
                                    // disabled={(actionType === "view") ? true : false}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display="flex" justifyContent="flex-start" direction="row" className='mb-30'>
                                <Grid item md={3} alignSelf="flex-start">
                                    <p className='filedName'>Description <span className='redText'>*</span></p>
                                </Grid>
                                <Grid item md={9}>
                                    <TextField
                                        // InputLabelProps={{
                                        //     shrink: true
                                        // }}
                                        id="variable" size="small"
                                        required
                                        label="Description" variant="outlined" type="text"
                                        value={description}
                                        onChange={(e) => { setDescription(e.target.value) }}
                                        multiline
                                        maxRows={4} rows={4}
                                        fullWidth
                                        className="multilineFieldWrapper"
                                        sx={{
                                            fontSize: "13px !important",
                                        }}
                                    />
                                </Grid>
                            </Grid>
                            <Grid container display="flex" justifyContent="center" direction="row">
                                <Button variant="contained" className={(name === "" || email === "" || description === "") ? 'primaryBtnContained disabled' : 'primaryBtnContained'}
                                    disabled={name === "" || email === "" || description === ""}
                                    onClick={() => ContactAction()}
                                >
                                    Contact
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Footer footerRef={footerRef} />
                </Grid>
                {/* <ContactIcon /> */}
                <IconButton
                    // edge="start"
                    className='contactFixedMainIcon'
                    // color='black'
                    name='+971543211447'
                    onClick={() => { handleWhatsApp(); }}
                >
                    <WhatsAppIcon sx={{ fontSize: "2rem", color: 'white' }} />
                </IconButton>

                <ToastMessage
                    showToast={isShowToast}
                    closeSnackBar={() => { closeSnackBar() }}
                    toastMessge={"Contact Form Submitted"}
                    type={"success"}
                    duration={3000}
                    horizontal="right"
                />
                <Drawer open={isActiveSideBar} onClose={() => { setIsActiveSideBar(false); }}>
                    <SideBar setIsActiveSideBar={setIsActiveSideBar} />
                </Drawer>
            </Grid >
        </>
    )
}

export default ContactUs