import React, { useState, useEffect } from 'react';
import {
    Grid, IconButton,
    Button,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import logoDark from './images/logoDark.png';
import logoBright from './images/logoBright.png';
import { useNavigate } from 'react-router-dom';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';



const SideBar = (props) => {
    const { setIsActiveSideBar } = props;
    const [activeMenu, setActiveManu] = useState("");
    const navigate = useNavigate();

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    return (
        <Grid container className='sideBarWrapper'>
            <Grid container className='sideTopBar'>
                <Grid container display="flex" justifyContent="space-between" className='sideBarTopLeft pb-10'>
                    <Grid container item justifyContent="space-between">
                        <IconButton
                            // edge="start"
                            onClick={() => { setIsActiveSideBar(false) }}
                            className='closeIconSideBar'
                        >
                            <CloseIcon sx={{ fontSize: (useWindowDimensions().width <= 425) ? 28 : 30 }} />
                        </IconButton>
                        <img src={logoDark} alt="Logo" className={(useWindowDimensions().width <= 425) ? 'logoStyle' : 'logoStyle'} />
                    </Grid>
                    {/* <Grid item sx={{ backgroundColor: "white !important" }}></Grid> */}
                </Grid>
                <Grid container display="flex" >
                    <Grid item className='sideBardMenu'>
                        <Grid container className='sideBarMenu'>
                            <Button
                                // edge="start"
                                className={activeMenu === "services" ? `sidebarMenuOption mb-30 activeColor` : `sidebarMenuOption mb-30`}
                                onClick={() => { setIsActiveSideBar(true) }}
                                endIcon={
                                    <NavigateNextIcon
                                        onClick={(e) => { e.preventDefault(); setActiveManu("services") }}
                                        sx={{ fontSize: "28px !important" }} />}
                            >
                                Services
                            </Button>
                            <Grid container className='sideMenuContent'>
                                <Grid className='menuWrapper'>
                                    <div className='sideBarmenuItem' onClick={() => {
                                        navigate("/instant-deployment-services");
                                    }}>Instant Deployment Services (IDS)</div>
                                    <div onClick={() => {
                                        navigate("/value-centric-skilling");
                                    }}
                                        className='sideBarmenuItem'>Value Centric Skilling (VCS)</div>

                                    <div className='sideBarmenuItem'
                                        onClick={() => {
                                            navigate("/proactive-managed-services");
                                        }}
                                    >Proactive Managed Services (PMS)</div >
                                </Grid>
                            </Grid>
                        </Grid>
                        {/* <Button
                            className='sidebarMenuOption mb-30'
                            onClick={() => { setIsActiveSideBar(true) }}
                        >
                            Blogs
                        </Button> */}
                        <Button
                            className='sidebarMenuOption mb-30'
                            onClick={() => {
                                setIsActiveSideBar(true);
                                navigate("/contactUs");
                            }}
                        >
                            Contact Us
                        </Button>
                        <Button
                            className='sidebarMenuOption mb-30'
                            onClick={() => {
                                navigate("/aboutUs");
                                setIsActiveSideBar(true)
                            }}
                        >
                            About Us
                        </Button>
                        <Button
                            className='sidebarMenuOption mb-30'
                            onClick={() => {
                                navigate("/termsAndConditions");
                                setIsActiveSideBar(true);
                            }}
                        >
                            Privacy Policy
                        </Button>
                        <Button
                            className='sidebarMenuOption'
                            onClick={() => {
                                navigate("/termsAndConditions");
                                setIsActiveSideBar(true);
                            }}
                        >
                            Terms of Service
                        </Button>
                    </Grid>
                    {/* <Grid container item flex={1} display="flex" direction="column" justifyContent="flex-start" alignItems="flex-start" className='menuRightWrapper'>
                        <p className='m-0 fs-28 menuRightHeadText'>Services</p>
                        <Grid item className='menuRightContent' display="flex" direction="column" justifyContent="flex-start" alignItems="flex-start">
                            <a href="" className='menuLink fs-16 mb-30'>Comprehensive Solution Enhancement Program (CSEP)</a>
                            <a href="" className='menuLink fs-16 mb-30'>Value Centric Upskilling Program (VCUP)</a>
                            <a href="" className='menuLink fs-16 mb-30'>Instant Deployment Services (IDS)</a>
                        </Grid>
                    </Grid> */}
                </Grid>

            </Grid>
        </Grid >
    )
}

export default SideBar