import React from 'react';
import {
    Grid,
} from '@mui/material';
import placeHolder from '../images/placeholder.jpg';
import vcupArchitech from '../images/vcupArchitech.jpeg';
import vcupUpskilling from '../images/vcupUpskilling.jpeg';





const VcupContent = () => {
    return (
        <Grid container display="flex" direction="column" justifyContent="space-between" className='serviceWrapper mt-20'>
            <p className='m-0 fs-28 fw-700 textAlign-left'>Why Choose Our Program</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Engaging, Practical Learning: </span> Step beyond traditional training with interactive, real-world learning in key tech areas. Courses are led by industry experts who bring insights from the field, ensuring you gain relevant, actionable knowledge. </p>
            <p className='m-0 fs-18 textAlign-left mt-10 mb-40'><span className='fw-700'>Tailored Learning Pathways: </span> FutecX provides personalized training that targets your specific needs and goals, ensuring the skills you develop are immediately applicable to your work and career growth. </p>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Program Features </p>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                <Grid item md={8} className='imageContainer'>
                    <img src={vcupArchitech} alt='csepOffers' className='csepOfferImage' />
                </Grid>
                <Grid item md={4}>
                    <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Flexibility: </span> Choose from online, in-person, or hybrid formats to suit your schedule and learning preferences. </p>
                    <p className='m-0 fs-18 textAlign-left mt-10 mb-10'><span className='fw-700'>Dynamic Curriculum: </span> Stay current with the latest tech trends and industry changes with our curriculum that updates based on real-time field learning from our experts. </p>
                    <p className='m-0 fs-18 textAlign-left mt-10 mb-10'><span className='fw-700'>Engagement Beyond Training: </span> Benefit from access to our learning community and Q&A forums for ongoing support and knowledge sharing. </p>
                    <p className='m-0 fs-18 textAlign-left mt-10 mb-10'><span className='fw-700'>Learning Outcomes: </span> Emerge from the program not just with new skills, but with the ability to apply them effectively in your current role and future projects. </p>
                </Grid>
            </Grid>
            <p className='m-0 fs-18 textAlign-left mt-10 mb-40'><span className='fw-700'>Support and Resources: </span> Enjoy post-training support, including follow-up sessions with trainers for three months after course completion, and stay connected through our active Q&A community. </p>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Our Focus Areas </p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'> We focus on essential, in-demand skills to keep you ahead: </p>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center" className='mb-40'>
                <Grid item md={5}>
                    <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Microsoft Azure Cloud: </span>Master Infrastructure, Applications, Data, and AI. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Microsoft 365: </span>Enhance productivity and collaboration. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Data Engineering:  </span>Build and manage effective data pipelines. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Microsoft Security: </span>Secure your applications and data. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Cyber Security Certifications: </span>Prepare for CISSP and CCSP certifications. </p>
                    <p className='m-0 fs-18 textAlign-left mb-10'><span className='fw-700'>Practical Use Cases and Demos: </span>Apply learning through real-world scenarios. </p>
                </Grid>
                <Grid item md={7} className='imageContainer'>
                    <img src={vcupUpskilling} alt='csepOffers' className='csepOfferImage' />
                </Grid>
            </Grid>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Pricing and Enrollment </p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Customized Pricing: </span>Costs are tailored based on your specific learning needs and total training hours. </p>
            <p className='m-0 fs-18 textAlign-left mb-30'><span className='fw-700'>Flexible Enrollment Options: </span>Whether enrolling individually or as a team, our program accommodates your needs. </p>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>Get Started with Us</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'>Ready to redefine your professional trajectory? Enroll in our Value-Centric Skilling today and bridge the gap between today’s skills and tomorrow’s opportunities. </p>
            <p className='m-0 fs-18 textAlign-left mb-50'><span className='fw-700'>Sign up </span> now to start your journey to becoming a tech leader of the future. </p>
        </Grid>
    )
}

export default VcupContent