import React, { useState, useEffect, useRef } from 'react';
import {
    Grid, Drawer
} from '@mui/material';
import Header from './Header';
import Banner from './Banner';
import Services from './Services';
import BlogContainer from './blogs/BlogContainer';
import Footer from './Footer';
import SideBar from './SideBar';
import { useLocation } from 'react-router-dom';



const ServicesPage = () => {
    const [isVisible, setIsVisible] = useState(true);
    const [isActiveSideBar, setIsActiveSideBar] = useState(false);
    const footerRef = useRef(null);
    const myTarget = useRef(null);
    const { pathname } = useLocation();

    const scrollToTarget = () => {
        myTarget.current.scrollIntoView({ behavior: 'smooth', block: "end" });
    };


    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                setIsVisible(!entry.isIntersecting);
            },
            {
                root: null, // observing intersections with the viewport
                threshold: 0.1 // trigger callback when 10% of the footer is visible
            }
        );

        if (footerRef.current) {
            observer.observe(footerRef.current);
        }

        return () => {
            if (footerRef.current) {
                observer.unobserve(footerRef.current);
            }
        };
    }, [footerRef]);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    console.log("isVisible", isVisible)

    return (
        <Grid container className='servicesPageWrap'>
            {(isVisible) && <Header setIsActiveSideBar={setIsActiveSideBar} />}
            <Services myTarget={myTarget} />
            {/* <BlogContainer /> */}
            <Footer footerRef={footerRef} />
            <Drawer open={isActiveSideBar} onClose={() => { setIsActiveSideBar(false); }}>
                <SideBar setIsActiveSideBar={setIsActiveSideBar} />
            </Drawer>
        </Grid>
    )
}

export default ServicesPage