import React from 'react';
import {
    Grid,
} from '@mui/material';
import csepOffer from '../images/csepOffer.jpeg';
import csepTechnologySolution from '../images/csepTechnologySolution.jpeg';



const CsepContent = (props) => {

    return (
        <Grid container display="flex" direction="column" justifyContent="space-between" className='serviceWrapper'>
            <p className='m-0 fs-28 fw-700 textAlign-left mt-20'>What we Offer</p>
            <p className='m-0 fs-24 fw-600 textAlign-left mt-30'>Systematic Assessment Phase:</p>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                <Grid item md={5} alignSelf="flex-start">
                    <p className='m-0 fs-18 textAlign-left mt-20 mb-10'>PMS begins with a comprehensive Systematic Support phase. During this phase, we conduct in-depth assessments of your existing infrastructure using advanced analytics and diagnostic tools. This allows us to identify deficiencies and areas for improvement across a broad spectrum of operational parameters, including security protocols, system performance, and reliability metrics. Additionally, we meticulously record the current expenditure of your implementation to uncover opportunities for cost optimization and financial efficiency in the long run. </p>
                </Grid>
                <Grid item md={7} className='imageContainer pt-5'>
                    <img src={csepOffer} alt='csepOffers' className='csepOfferImage' />
                </Grid>
            </Grid>
            <p className='m-0 fs-18 textAlign-left mt-10 mb-10'>By leveraging these insights, our experts devise tailored strategies to fortify your systems, ensuring robust security, optimized performance, and enhanced reliability. With CSEP, FutecX empowers your organization to achieve technological excellence and unlock unparalleled value from your investments.</p>
            <p className='m-0 fs-24 fw-600 textAlign-left mt-30'>Execution Phase:</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'>Following the assessment, the Execution Phase involves implementing the tailored solutions devised during the Systematic Support phase. Our team of experts collaborates closely with your organization to ensure seamless integration of enhancements into your existing systems. Key features of this phase include: </p>
            <Grid container direction="row" display="flex" justifyContent="space-between" alignItems="center">
                <Grid item md={8} className='imageContainer'>
                    <img src={csepTechnologySolution} alt='csepOffers' className='csepOfferImage' />
                </Grid>
                <Grid item md={4}>
                    <p className='m-0 fs-18 textAlign-left mt-20 mb-10'><span className='fw-700'>Security Fortification: </span> Strengthening security protocols to safeguard sensitive data and prevent unauthorized access. </p>
                    <p className='m-0 fs-18 textAlign-left mt-10 mb-10'><span className='fw-700'>Performance Optimization: </span> Streamlining system performance to maximize efficiency and reduce latency. </p>
                    <p className='m-0 fs-18 textAlign-left mt-10 mb-10'><span className='fw-700'>Reliability Enhancement: </span> Improving system reliability through proactive maintenance and redundancy strategies. </p>
                    <p className='m-0 fs-18 textAlign-left mt-10 mb-10'><span className='fw-700'>Cost Optimization: </span> Identifying opportunities to reduce costs through infrastructure right-sizing, resource allocation, and efficient cloud service management. </p>
                </Grid>

            </Grid>
            <p className='m-0 fs-24 fw-600 textAlign-left mt-30'>Value Realization Phase :</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'>The Value Realization Phase is designed to ensure that values are attained by offering continuous support throughout the year to sustain improvements across performance, security, reliability, cost optimization, and operational excellence. </p>
            <p className='m-0 fs-18 textAlign-left mt-10 mb-10'>By implementing these strategies & proactively fixing potential issues before they escalate, CSEP not only enhances your technological solutions but also significantly reduces the number of reactive incidents. Our proactive approach ensures that your systems remain secure, efficient, and reliable, allowing your organization to focus on innovation and growth. </p>
            <p className='m-0 fs-18 textAlign-left mt-10 mb-50'>With PMS, FutecX empowers your organization to achieve technological excellence and unlock unparalleled value from your investments through comprehensive, long-term support. </p>
            <p className='m-0 fs-28 fw-700 textAlign-left'>Get Started with Us</p>
            <p className='m-0 fs-18 textAlign-left mt-20 mb-10'>At FutecX, we believe in building lasting relationships with our customers. We're not just service providers; we're your dedicated partners, committed to your success every step of the way. </p>
            <p className='m-0 fs-18 textAlign-left mt-10 mb-50'>Let's embark on this journey towards excellence together! </p>
        </Grid >
    )
}

export default CsepContent