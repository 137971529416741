import React from 'react';
import {
    Grid, Button, CardContent, Card
} from '@mui/material';
import placeHolder from '../images/placeholder.jpg';



const BlogCard = (props) => {
    const { carouselData } = props;

    return (
        <Card sx={{ width: "350px !important" }} className='blogCardWrap'>
            <img src={placeHolder} alt='placeHolder' className='blogCardImage' />
            <p className='m-0 mt-25 fs-16 fw-700'>{carouselData.technology}</p>
            <p className='m-0 mt-10 fs-24 fw-800'>{carouselData.blogHeading}<span className='fs-20 fw-600'>{carouselData.bogHeading2}</span></p>
            <p className='m-0 mt-10 fs-16'>{carouselData.blogDesc}</p>
            <Grid container className='mt-20' display="flex" direction="row" justifyContent="flex-start" alignItems="center">
                <img src={placeHolder} alt='placeHolder' className='blogProfileImage' />
                <Grid item className='ml-20'>
                    <p className='m-0 fs-14 fw-700'>{carouselData.blogCreator}</p>
                    <p className='m-0 mt-5 fs-14'>{`${carouselData.date} • ${carouselData.duration} min read`}</p>
                </Grid>
            </Grid>
        </Card>
    )
}

export default BlogCard