import React, { useEffect, useRef, useState } from 'react';
import {
    Grid, Button
} from '@mui/material';
import vcupImage from '../images/VCEP1.png';



const VcupBanner = () => {
    const rightRef = useRef(null);
    const leftRef = useRef(null);

    function useWindowDimensions() {
        const [windowDimensions, setWindowDimensions] = useState({
            width: window.innerWidth,
            height: window.innerHeight
        });

        useEffect(() => {
            const handleResize = () => {
                setWindowDimensions({
                    width: window.innerWidth,
                    height: window.innerHeight
                });
            };

            window.addEventListener('resize', handleResize);

            // Clean up the event listener when the component unmounts
            return () => window.removeEventListener('resize', handleResize);
        }, []); // Empty dependency array means this effect will only run once, similar to componentDidMount

        return windowDimensions;
    }

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-right');
                        observer.unobserve(entry.target); // Stop observing the element after animation
                    }
                });
            },
            {
                threshold: 0 // Triggers when 50% of the element is visible
            }
        );

        if (rightRef.current) {
            observer.observe(rightRef.current);
        }

        return () => {
            if (rightRef.current) {
                observer.disconnect(); // Clean up the observer if the component unmounts
            }
        };
    }, []);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries, observer) => {
                entries.forEach(entry => {
                    if (entry.isIntersecting) {
                        entry.target.classList.add('start-animation-left');
                        observer.unobserve(entry.target); // Detach observer after the animation
                    }
                });
            },
            {
                threshold: 0 // Adjust based on when you want the animation to start
            }
        );

        if (leftRef.current) {
            observer.observe(leftRef.current);
        }

        return () => {
            observer.disconnect(); // Clean up the observer
        };
    }, []);

    return (
        <Grid container display="flex" direction={(useWindowDimensions().width <= 800) ? "column-reverse" : "row"} justifyContent="space-between" className={(useWindowDimensions().width <= 800) ? 'csepWrapper mb-40 mt-30' : 'csepWrapper mb-40'}>
            <Grid item md={6} ref={leftRef} className='textAlign-left animationContainer rightContainerBanner' display="flex" direction="column" justifyContent="center">
                <p className='m-0 serviceHeadText pl-20'>Value Centric Skilling (VCS)</p>
                <p className='m-0 mt-30 serviceOverview2 pl-20'>Master the Skills for Tomorrow's Tech Challenges, Today! </p>
                <p className='m-0 mt-20 serviceOverviewText pl-20'>In today’s rapidly evolving tech landscape, leading the way means staying ahead with the right skills. FutecX's Value-Centric Skilling offers a dynamic, hands-on approach to equip you with the skills needed for tomorrow's tech challenges in a format that fits your life. </p>
            </Grid>
            <Grid item md={6} ref={rightRef} className={(useWindowDimensions().width <= 800) ? 'leftImageContainer mb-20' : 'leftImageContainer'} >
                <img src={vcupImage} alt='csepImage' className='vcupBannerImage' />
            </Grid>
        </Grid>
    )
}

export default VcupBanner